// counter js

function initializeCounter(triggerEl,counterEl){
    // debugger
    const counter = document.querySelector(counterEl);
    const trigger = document.querySelector(triggerEl)
    let num = 0;
    const count=()=>{
        if(num <= counter.dataset.stop)
        ++num;

        counter.innerHTML = counter.id == 'counter1' ? num +'%'  : num + '+';
    }

    const observer = new IntersectionObserver((el)=>{

        if(el[0].isIntersecting){
            const interval = setInterval(() => {
                if(num < counter.dataset.stop){
                    count()
                }else{
                    clearInterval(interval)
                }
            },counter.dataset.speed);
        }
    },{threshold:[0]});

    observer.observe(trigger);
}


initializeCounter("#startCounter1","#counter")
initializeCounter("#startCounter1","#counter1")
initializeCounter("#startCounter1","#counter2")

window.initializeCounter = initializeCounter




